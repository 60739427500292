<template>
	<!-- <div id="telephoneLogin" v-loading="loading" element-loading-text="正在登陆中"> -->
	<div class="telephoneLogin-box">
		<div v-if="whole">
			<!-- <div class="title">
       用户账号登录
      </div>-->
			<!-- <div
        class="loginBox"
        v-loading="loading"
        element-loading-text="正在登陆中"
        style="width: 640px;display: flex;"
      >-->
			<div class="loginBox" v-loading="loading" element-loading-text="正在登陆中" style="width: 400px;display: flex;">
				<div>
					<!-- <div style="width: 400px;"> -->
					<div class="postbox">
						<div class="verCode" v-if="verification" @click="vercde">验证码登陆</div>
						<div class="verCodes" v-else @click="vercde">验证码登陆</div>
						<div class="passwords" v-if="passwordlogin" @click="paslad">密码登陆</div>
						<div class="password" v-else @click="paslad">密码登陆</div>
					</div>
					<div v-if="verification">
						<div class="postbox">
							<input class="telephone" v-model="telephone" maxlength="11" minlength="11" placeholder="请输入常用手机号码" @change="chang_mobile" />
						</div>
						<div class="codeFrame">
							<input class="code" v-model="captcha" placeholder="请输入验证码" @keyup.enter="login" />
							<el-button class="getCode" type="text" @click="getCode" v-show="is_getCode==0">获取验证码</el-button>
							<el-button class="getCode" type="text" v-show="is_getCode!=0" :disabled="true">{{captchaNum}}s</el-button>
						</div>
						<div class="postbox" style="height: 30px;">
							<div class="forgetpassword"></div>
						</div>
						<el-button class="loginBtn tc" @click="login">登录/注册</el-button>
					</div>
					<div v-if="passwordlogin">
						<div class="postbox">
							<input class="telephone" v-model="telephone" maxlength="11" minlength="11" placeholder="请输入手机号码" @change="chang_mobile" />
						</div>
						<div class="postbox">
							<input class="telephone" type="password" v-model="PassWord" placeholder="请输入密码" @keyup.enter="passwordLand" />
						</div>
						<div class="postbox">
							<div class="forgetpassword" @click="forgetpass">忘记密码?</div>
						</div>
						<el-button class="loginBtn tc" @click="passwordLand">登录</el-button>
					</div>
				</div>
				<!-- <div class="loginBox-right" style="width: 200px;position: relative;">
          <div class="wx-title tc">
            <img src="../../../assets/images/saoma.png" />
          </div>
          <div id="reneCode"></div>
        </div>-->
			</div>
		</div>
		<!-- 忘记密码时显示 -->
		<div class="telephoneLogin" v-else>
			<div class="loginBox" v-loading="loading" element-loading-text="正在登陆中">
				<div class="postbox">
					<div class="delta" v-if="cancel" @click="delta">返回</div>
					<div class="delta" v-else @click="iscancel">取消</div>
				</div>
				<div class="postbox">
					<input class="telephone" v-model="telephone" maxlength="11" minlength="11" placeholder="请输入常用手机号码" @change="chang_mobile" />
				</div>
				<div class="codeFrame">
					<input class="code" v-model="captcha" placeholder="请输入验证码" />
					<el-button class="getCode" type="text" @click="getCode" v-show="is_getCode==0">获取验证码</el-button>
					<el-button class="getCode" type="text" v-show="is_getCode!=0" :disabled="true">{{captchaNum}}s</el-button>
				</div>
				<div class="postbox">
					<input class="telephone" type="password" v-model="newpassword" placeholder="请输入新密码" @keyup.enter="agressupdate" />
				</div>
				<el-button class="loginBtn tc" @click="agressupdate">确认</el-button>
			</div>
		</div>
		<!-- 机构弹框组件 -->
		<organizationDialog :organDialog="organDialog" @close="close" @getPage="getPage" :organList="organList" @getList="getList" />


	</div>

</template>
<script type="text/ecmascript-6">
	import * as validata from "@/utils/validata";
	import {
		error,
		warning,
		success
	} from "@/utils/notification";
	import organizationDialog from "@/components/Dialog"; //机构弹框组件
	export default {
		components: {
			organizationDialog
		},
		data() {
			return {
				cancel: true,
				telephone: "",
				PassWord: "", //密码
				captcha: "",
				whole: true, //默认显示
				newpassword: "", //新密码
				// 是否点击过获取验证码标识
				is_getCode: 0,

				// 获取验证码的倒计时
				captchaNum: 60,
				loading: false,
				islogin: false,
				source: "",
				verification: true, //默认验证码登陆
				passwordlogin: false, //密码登陆
				organDialog: false, //机构弹窗
				organList: [], //结构列表数据
				page: 1, //初始分页
			};
		},
		// inject:['reload'],
		created() {
			if (window.location.hash.indexOf('?') > -1) {
				let obj = window.location.hash.split('?')[1].split('&')
				let token = obj[0] && obj[0].split('=')[1]
				if (token && token === 'fromDasai') {
					this.organDialog=true
					this.getOrganizationList(); //获取机构列表
				}
			}

			if (
				this.$route.params.type &&
				this.$route.params.type === "updatePassword"
			) {
				this.whole = false;
				this.source = this.$route.params.type;
			} else {
				let adminToken = sessionStorage.getItem("adminToken");
				let organizationId=sessionStorage.organizationId
				if(adminToken&&!organizationId){
					this.organDialog=true
					this.getOrganizationList(); //获取机构列表
				}
				if (adminToken && adminToken != undefined&&organizationId) {
					this.$router.push({
						name: "AdminIndex",
						params: {}
					});
				} else {
					this.whole = true;
				}
			}
			// 从empty页面跳转过来
			if (this.$route.query.flag == 1) {
				this.organDialog=true
				this.getOrganizationList(); //获取机构列表
			}
			let hash = window.location.hash.split('?')[1]
			let tokenFlag = hash && hash.split('=')[1]
			if (tokenFlag == 2) {
				// 从融创绵阳主系统跳转过来，发布空间
				this.organDialog=true
				this.getOrganizationList(); //获取机构列表
			}
		},
		mounted() {
			// new WxLogin({
			//   id: "reneCode",
			//   appid: this.URL.cjt,
			//   scope: "snsapi_login",
			//   redirect_uri: this.URL.weitianshi + "/auth/wechat/callback",
			//   href: "https://www.weitianshi.cn/static/css/qrCode.css",
			//   state: Date.now()
			// });
		},
		methods: {
			chang_mobile() {
				if (!validata.checkPhoneNumber(this.telephone)) {
					this.telephone = "";
				}
			},
			// 获取验证码
			getCode() {
				if (!validata.checkPhoneNumber(this.telephone)) {
					warning("请正确输入手机号码");
				} else {
					this.$http
						.post(this.URL.sendSms, {
							mobile: this.telephone
						})
						.then(res => {
							if (res.data.code === 200) {
								this.is_getCode = 1;
								var timer = setInterval(() => {
									this.captchaNum--;
								}, 1000);
								setTimeout(() => {
									clearInterval(timer);
									this.captchaNum = 60;
									this.is_getCode = 0;
								}, 60000);
							} else {
								// error(res.data.error_msg);
							}
						})
						.catch(err => {
							console.log(err);
							this.loading = false;
							error(err.response.data.message);
						});
				}
			},
			// 注册或者登录
			login() {
				if (this.telephone && this.captcha) {
					if (!validata.checkPhoneNumber(this.telephone)) {
						warning("请正确输入手机号码");
						return false;
					}
					this.loading = true;
					this.$http
						.post(this.URL.adminUrl.codeLogin, {
							mobile: this.telephone,
							code: this.captcha
						})
						.then(res => {
							if (res.data.code === 200) {
								sessionStorage.adminToken = res.data.data.token;
								sessionStorage.system_token = res.data.data.system_token;
								localStorage.adminName = res.data.data.userInfo.name;
								this.loading = false;
								this.getOrganizationList(); //获取机构列表
								this.organDialog = true; //开启弹框
								localStorage.removeItem("jumpUrl");
								// window.open(this.URL.rcmyUrlEmpty + '/#/empty?token=' + res.data.data.system_token+ '&name=name', '_self')
							} else {
								// error(res.data.error_msg);
								this.loading = false;
							}
						})
						.catch(err => {
							this.loading = false;
							error(err.response.data.message);
						});
				} else {
					warning("请正确填写手机号码和验证码");
				}
			},
			// 返回
			delta: function() {
				if (this.source && this.source === "updatePassword") {
					this.$router.go(-1);
				} else {
					this.whole = true;
				}
			},
			// 忘记密码
			forgetpass: function() {
				this.whole = false;
			},
			vercde() {
				this.passwordlogin = false;
				this.verification = true;
			},
			paslad() {
				this.passwordlogin = true;
				this.verification = false;
			},
			//密码登陆
			passwordLand: function() {
				if (this.telephone && this.PassWord) {
					if (!validata.checkPhoneNumber(this.telephone)) {
						warning("请正确输入手机号码");
						return false;
					}
					this.loading = true;
					this.$http
						.post(this.URL.adminUrl.passwordLogin, {
							mobile: this.telephone,
							password: this.PassWord
						})
						.then(res => {
							if (res.data.code === 200) {
								sessionStorage.adminToken = res.data.data.token;
								sessionStorage.system_token = res.data.data.system_token;
								localStorage.adminName = res.data.data.userInfo.name;
								this.loading = false;
								this.getOrganizationList(); //获取机构列表
								this.organDialog = true; //开启弹框
								localStorage.removeItem("jumpUrl");
								// window.open(this.URL.rcmyUrlEmpty + '/#/empty?token=' + res.data.data.system_token+ '&name=name', '_self')
								// if (this.$route.query.flag == 10 && this.$route.query.show == 1){
								// 	// 发布政策 去融创绵阳
								// 	window.open(this.URL.rcmyUrl + '/#/empty?token=' + sessionStorage.system_token + '&show=3', '_self')
								// } else if (this.$route.query.flag == 10 && this.$route.query.show == 3) {
								// 	// 发布空间 去融创绵阳
								// 	window.open(this.URL.rcmyUrl + '/#/empty?token=' + sessionStorage.system_token + '&show=2', '_self')
								// } else if (this.$route.query.flag == 10 && this.$route.query.show == 0) {
								// 	// 载体管理，最终去招商通的首页 去融创绵阳
								// 	window.open(this.URL.rcmyUrl + '/#/empty?token=' + sessionStorage.system_token + '&show=0', '_self')
								// }

							} else {
								// error(res.data.error_msg);
								this.loading = false;
							}
						})
						.catch(err => {
							this.loading = false;
							error(err.response.data.message);
						});
				} else {
					error("请正确填写手机号码和密码");
				}
			},
			//取消
			iscancel: function() {
				this.$router.push({
					name: "AdminIndex",
					params: {}
				});
			},
			//确认修改密码
			agressupdate: function() {
				if (this.telephone && this.captcha && this.newpassword) {
					if (!validata.checkPhoneNumber(this.telephone)) {
						warning("请正确输入手机号码");
						return false;
					}
					this.loading = true;
					this.$http
						.post(this.URL.adminUrl.updatePassword, {
							mobile: this.telephone,
							password: this.newpassword,
							code: this.captcha
						})
						.then(res => {
							if (res.data.code === 200) {
								localStorage.clear();
								sessionStorage.removeItem("adminToken");
								sessionStorage.removeItem('organizationName')
								sessionStorage.removeItem('organizationId')
								sessionStorage.removeItem('organizationImg')
								success("密码修改成功,请重新登录");
								this.$router.go(0);
							} else {
								// error(res.data.error_msg);
							}
							this.loading = false;
						})
						.catch(err => {
							this.loading = false;
							error(err.response.data.message);
						});
				} else {
					warning("请正确填写手机号码和验证码");
				}
			},

			//弹框获取当前页码
			getPage(data) {
				this.page = data;
				this.getOrganizationList();
			},
			//关闭组件弹窗事件
			close() {
				this.organDialog = false;
			},
			//弹框获取机构数据
			getOrganizationList() {
				this.$http
					.get(this.URL.adminUrl.organizationList, {
						params: {
							page: this.page
						}
					})
					.then(res => {
						this.organList = res.data.data;
					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			//弹框获取点击机构的信息
			getList(data) {
				this.$store.commit("setOrganInfo", data)
				sessionStorage.organizationId = data.id;
				sessionStorage.organizationName = data.name;
				sessionStorage.organizationImg = data.image
				if (this.$route.query.show == 1 || this.$route.query.app_id == 2) {
					// 当前从融创绵阳主页过来，去发布政策
					this.$router.push('/addArticals')
					return false
				}
				let obj = window.location.hash.split('?')[1]
				let show1 = obj && obj.split('=')[1]
				if (this.$route.query.app_id == 3 || this.$route.query.show == 3 || show1 == 1) {
					// 当前从融创绵阳主系统过来，去发布空间
					this.$router.push('/AddSpace')
					return false
				}
				let path = "";
				if (localStorage.jumpUrl) {
					path = localStorage.jumpUrl;
				} else {
					path = "/";
				}
				this.$router.push('/');
			}
		}
	};
</script>
<style scoped>
  .telephoneLogin-box{
		position: relative;
		width: 100%;
		height: 100vh;
	}
	#reneCode {
		position: absolute;
		top: 74px;
		right: 78px;
		width: 160px;
		height: 160px;
	}

	.wx-title {
		font-size: 13px;
		color: #5e6d82;
		margin-bottom: 70px;
	}

	.wx-title img {
		width: auto;
		height: 13px;
	}

	.loginPanel .title {
		display: none;
	}

	.telephoneLogin {
		background-color: #f3f4f8;
		min-height: 100vh;

	}

	/* .title {
		width: 400px;
		padding-top: 60px;
		margin: 0 auto 10px;
		text-align: center;
		font-size: 22px;
		line-height: 44px;
		color: #000;
	} */

	.loginBox {
		width: 400px;
		box-sizing: border-box;
		padding: 50px;
		margin: 100px auto;
		background-color: #fff;
		border-radius: 2px;
		box-shadow: 0 4px 4px 0 rgba(64, 88, 122, 0.1);
		margin-bottom: 200px;
	}

	.postbox input {
		padding: 15px 0;
		font-size: 12px;
		border-bottom: 1px solid #e0e6ed;
		width: 300px;
		margin: 5% 0 0 0;
	}

	.postbox {
		cursor: pointer;
	}

	.verCode {
		width: 100px;
		float: left;
		font-size: 20px;
		color: #1abcfd;
		border-bottom: 2px solid #1abcfd;
		margin-right: 20px;
	}

	.passwords {
		width: 80px;
		float: left;
		font-size: 20px;
		color: #1abcfd;
		border-bottom: 2px solid #1abcfd;
		margin-right: 20px;
	}

	.delta {
		width: 40px;
		font-size: 20px;
		color: #1abcfd;
		border-bottom: 2px solid #1abcfd;
	}

	.password,
	.verCodes {
		width: 100px;
		float: left;
		font-size: 20px;
		color: #666;
		margin-right: 20px;
	}

	.forgetpassword {
		font-size: 18px;
		color: #666;
		float: right;
		margin-top: 10px;
		margin-right: 30px;
	}

	.forgetpassword:hover {
		color: #1abcfd;
	}

	.codeFrame {
		font-size: 12px;
		border-bottom: 1px solid #e0e6ed;
		width: 300px;
		overflow: hidden;
		margin: 5% 0 0 0;
	}

	.code {
		float: left;
		width: 180px;
		padding: 15px 0;
		border: 0;
	}

	.getCode {
		float: left;
		/* width: 60px; */
		border: 0;
		color: #4395ff;
		margin-top: 4px;
		margin-left: 18px;
	}

	.loginBtn {
		text-align: center;
		background-color: #1abcfd;
		margin-top: 20px;
		color: #fff;
		height: 46px;
		font-size: 16px;
		width: 300px;
	}

	input {
		border: 0;
		outline: none;
		box-sizing: border-box;
	}
</style>
